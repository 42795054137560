<template>
  <div class="price-card">
    <div class="card-header" @click="collapsed = !collapsed">
      <div>{{ $t("cart_total_title") }}</div>
      <template>
        <div
          class="collapse-button"
          :class="{ collapsed: collapsed }"
          v-if="orderDetails.total || orderDetails.total === 0"
        >
          <div class="price-total" v-if="!collapsed">
            <div class="price-original">
              <span v-if="showDiscount">{{
                orderDetails.originalTotal | currency
              }}</span>
            </div>
            <div class="price-value">
              {{ orderDetails.total | currency }}
            </div>
          </div>
          <i class="material-icons">arrow_drop_down</i>
        </div>
        <div v-else class="skeleton-line"></div>
      </template>
    </div>

    <div class="card-description" v-if="collapsed">
      <price-decomposition show-total :show-discount="showDiscount" />
    </div>

    <slot name="promoCodeField" />

    <slot name="agreementCheckbox" />

    <div class="card-button">
      <slot name="cardButton" />
      <!-- <mem-button @click="$emit('on-submit')" :loading="processing">
        <template v-if="step === 'payment'">
          <span v-if="killerDeal">{{ $t("cart_total_confirm") }}</span>
          <span v-else>{{ $t("cart_total_pay") }}</span>
        </template>
        <span v-else>{{ $t("cart_total_checkout") }}</span>
      </mem-button> -->
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {
    // "mem-button": () => import("@/components/base/BaseButton.vue"),
    "price-decomposition": () => import("./PriceDecomposition.vue"),
  },
  props: {
    step: {
      type: String,
      default: "details",
    },
    processing: {
      type: Boolean,
      default: false,
    },
    showDiscount: {
      type: Boolean,
      default: false,
    },
    killerDeal: Boolean,
  },
  data: () => ({
    collapsed: false,
  }),
  computed: {
    ...mapGetters(["orderDetails"]),
  },
};
</script>
<style lang="scss" scoped>
.price-card {
  width: 496px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  padding: 45px 40px 32px 40px;
  display: flex;
  flex-direction: column;
  .skeleton-line {
    height: 24px;
    width: 110px;
    position: relative;
    background-color: #e9e9e9;
    overflow: hidden;
    &::after {
      @include animatedLine("light");
    }
  }
  .card-header {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    min-height: 24px;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    @include cursorPointer;
    .collapse-button {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      .price-total {
        position: relative;
        .price-original {
          height: 22px;
          font-size: 14px;
          font-weight: 600;
          line-height: 22px;
          color: #cc092f;
          text-decoration: line-through;
          text-align: end;
          position: absolute;
          top: -22px;
          right: 0;
        }
        .price-value {
          font-size: 22px;
          line-height: 22px;
          font-weight: 700;
        }
      }
    }
    .collapsed {
      i {
        transform: rotate(180deg);
      }
    }
  }
  .card-description {
    padding-top: 40px;
  }
  .card-button {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 36px;
    // button {
    //   height: 56px;
    //   border-radius: 32px;

    //   font-size: 14px;
    //   font-weight: 700;
    //   letter-spacing: 0.04em;
    // }
  }
}
</style>